<template>
  <div :class="$style.set" :data-game="game">
    <header :class="$style.header">
      <div :class="$style.title" :title="name">
        <span :class="$style.text">{{ name }}</span>
      </div>
      <div :class="$style.aside">
        <div :class="$style.info">
          <img :class="$style.icon" src="./assets/info.svg?url" alt="info" />
          <div :class="$style.tooltip">
            <span :class="$style.text">{{ t('message') }}</span>
          </div>
        </div>
        <ui-button
          v-if="showBuySet"
          fill
          uppercase
          :class="$style.buy"
          :disabled="lockBuySet"
          theme="accent"
          size="medium"
          @click="onBuySet"
          v-analytics.click.gtm="['paripass', 'fantasy', 'buy_cards_set_click']"
        >
          {{ t('buy') }}
        </ui-button>
      </div>
    </header>

    <ui-slider
      :items="items"
      :gap="0.8"
      :per-page="slidesPerPage"
      :class="$style.slider"
    >
      <template #default="{ items }">
        <div :class="$style.item">
          <slot v-for="item in items" :item="item" />
        </div>
      </template>
    </ui-slider>
  </div>
</template>

<i18n>
{
  "ru": {
    "buy": "Купить",
    "message": "Купить набор можно, если у тебя ещё нет карточек из команды. Это дешевле."
  }
}
</i18n>

<script setup>
import UiButton from '~/components/ui/button'
import UiSlider from '~/components/ui/slider'

const { t } = useI18n()
const { $matchMedia } = useNuxtApp()

const $emit = defineEmits(['buy-set'])
const $props = defineProps({
  name: {
    type: String,
    default: ''
  },
  items: {
    type: Array,
    default: () => []
  },
  showBuySet: {
    type: Boolean,
    default: false
  },
  lockBuySet: {
    type: Boolean,
    default: false
  },
  game: {
    type: String,
    required: true
  }
})

const isDownMd = computed(() => $matchMedia.down('md'))
const isDownSm = computed(() => $matchMedia.down('sm'))
const slidesPerPage = computed(() =>
  isDownSm.value ? 2 : isDownMd.value ? 3 : 5
)

const onBuySet = () => {
  if (!$props.lockBuySet) {
    $emit('buy-set')
  }
}
</script>

<style lang="scss" module>
.set {
  --fantasy-set-bg: #252c3e;

  background-color: var(--fantasy-set-bg);
  border-radius: 0.6em;
  padding: 3em 3.2em 2.4em;
  @include down(sm) {
    padding: 2.4em 1.6em 2.4em;
  }
  & + & {
    margin-top: 4em;
    @include down(sm) {
      margin-top: 2.4em;
    }
  }
}

.set[data-game='dota'] {
  --fantasy-set-bg: #15244e;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4em;
  @include down(sm) {
    margin-bottom: 2.8em;
    display: block;
  }
}
.title {
  min-width: 0;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  @include down(sm) {
    display: block;
    text-align: center;
    margin-bottom: 1.6em;
  }
  .text {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4em;
    line-height: 142%;
    text-transform: uppercase;
    white-space: nowrap;
    color: #ffffff;
  }
}
.info {
  position: relative;
  margin-right: 2em;
  @include down(sm) {
    margin-right: 0;
    margin-left: 2em;
  }
  .icon {
    width: 2.5em;
    height: 2.5em;
  }
  &:hover .tooltip {
    opacity: 1;
    transform: translate(50%, -1em);
    @include down(sm) {
      transform: translate(4.25em, -1em);
    }
  }
  .tooltip {
    opacity: 0;
    position: absolute;
    width: 22.8em;
    right: 50%;
    text-align: center;
    bottom: 100%;
    transform: translate(50%, 0);
    background-color: #505c70;
    padding: 1em 1.4em;
    z-index: 10;
    border-radius: 0.4em;
    pointer-events: none;
    transition:
      0.3s opacity,
      0.3s transform;
    @include down(sm) {
      right: 100%;
      transform: translate(4.25em, 0);
    }
    &:after {
      content: '';
      border-top: 0.6em solid #505c70;
      border-left: 0.5em solid transparent;
      border-right: 0.5em solid transparent;
      position: absolute;
      top: 100%;
      right: 50%;
      transform: translate(50%, 0);
      @include down(sm) {
        right: 3em;
      }
    }
    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 1.2em;
      line-height: 120%;
    }
  }
}
.aside {
  display: flex;
  align-items: center;
  @include down(sm) {
    flex-direction: row-reverse;
  }
}
.slider {
}
.slide {
  @include down(md) {
    padding-left: 5em;
    padding-right: 5em;
  }
}
.item {
  height: 100%;
  > * {
    height: 100%;
  }
}
.buy {
  padding: 1.3em;
  min-width: 21.8em;
  @include down(sm) {
    width: 100%;
  }
}
</style>
