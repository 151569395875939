<template>
  <div :class="$style.overlay" @click.stop>
    <img :class="$style.icon" src="../assets/in-collection.svg?url" alt="" />
  </div>
</template>

<script>
export default {
  name: 'FantasyCommonCardOverlayInCollection'
}
</script>

<style lang="scss" module>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.5);
  border-radius: inherit;
  z-index: 5;
  padding: 1.2em;
  cursor: default;
  @include down(sm) {
    padding: 0.8em;
  }
}
.icon {
  width: 4em;
  height: 4em;
  @include down(sm) {
    width: 2.4em;
    height: 2.4em;
  }
}
</style>
